import { montserratFont, gilmerFont } from '@/utils/fonts';
import { sword } from '@swordhealth/ui-corporate/dist/themes';

const theme = {
  ...sword,
  colors: {
    ...sword.colors,
    inputs: {
      bg: sword.colors.white,
      placeholder: 'rgba(48, 38, 29, 0.5)',
      text: sword.colors.grey[800],
      border: sword.colors.grey[500],
      borderFocus: sword.colors.grey[800],
      error: sword.colors.negative.default,
    },
  },
  typography: {
    ...sword.typography,
    family: {
      body: `${montserratFont.style.fontFamily}, sans-serif`,
      title: `${gilmerFont.style.fontFamily}, sans-serif`,
    },
  },
  flexboxgrid: {
    gutterWidth: 20,
    outerMargin: 36,
    containerWidth: 1150,
  },
};

export default theme;
