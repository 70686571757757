import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

import { pageview } from '@/lib/gtm';

export default function GTMPageViewTracker() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    const handleRouteChange = (url) => {
      pageview(url);
    };

    const url = pathname + searchParams.toString();
    handleRouteChange(url);
  }, [pathname, searchParams]);

  return null;
}
