export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

export function pageview(url) {
  if (!window.dataLayer) return null;

  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
}

export function trackEvent(event, props = {}) {
  if (!window.dataLayer) return null;

  window.dataLayer.push({
    event: event,
    ...props,
  });
}
