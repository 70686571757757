export function isInWhiteList({ unit = 'thrive', pathName }) {
  if (pathName.includes('/campaign')) return true;

  const mapped = list.map((el) => `/${unit}/` + el.toLowerCase());

  return mapped.includes(pathName);
}

export const list = [
  'pepsico',
  'gd',
  'livewell',
  'statefarm',
  'smarthealth',
  'fmcna',
  'sysco',
  'davita',
  'Nucor',
  'hm',
  'danaher',
  'msk',
  'mcdlicensees',
  'sedgwick',
  'baptisthealth',
  'phillips66',
  'Omnicom',
  'concordiaplans',
  'fcf',
  'saic',
  'sdi',
  'hft',
  'nov',
  'dhl',
  'ufpi',
  'sefl',
  'lamresearch',
  'msi',
  'bechtel',
  'thomsonreuters',
  'schneider',
  'advantagesolutions',
  'petco',
  'schwans',
  'turner',
  'abk',
  'ttec',
  'hearst',
  'safran',
  'wood',
  'rexel',
  'schreiber',
  'beacon',
  'Veralto',
  'pearson',
  'crowncastle',
  'Timken',
  'dexcom',
  'api',
  'marvell',
  'swire',
  'intuitive',
  'menasha',
  'cottagehealth',
  'CommScope',
  'hologic',
  'tg',
  'lcg',
  'arcbest',
  'autoliv',
  'olympus',
  'Deluxe',
  'Clyde',
  'wabash',
  'uscs',
  'hines',
  'dominos',
  'footlocker',
  'EPAM',
  'cohnreznick',
  'aaacluballiance',
  'aleragroup',
  'pvh',
  'healthandwellbeing',
  'merit',
  'imi',
  'universityofphoenix',
  'mhe',
  'strategic_education',
  'gestamp',
  'nasdaq',
  'ssi',
  'carters',
  'axaxl',
  'acs',
  'netsmart',
  'ropesgray',
  'cooley',
  'fordhamuniversity',
  'govcio',
  'grede',
  'carolinadealerships',
  'zsassociates',
  'Aspen',
  'accolade',
  'firstwatch',
  'ryam',
  'potlatchdeltic',
  'recology',
  'bellandevans',
  'festivalfoods',
  'cort',
  'dometic',
  'pennentertainment',
  'ivanti',
  'flsmidth',
  'healthcatalyst',
  'cci',
  'DOSP',
  'bottomline',
  'mtf',
  'q2',
  'flow',
  'uvc',
  'insmed',
  'graymont',
  'jss',
  'ing',
  'simplisafe',
  'hopecollege',
  'mimedx',
  'mcclatchy',
  'overstock',
  'whartonsmith',
  'blueline',
  'ruppert',
  'packsize',
  'keeley',
  'johnsonelectric',
  'aptia',
  'globus',
  'innio',
  'laird',
  'weber',
  'arjo',
  'paypal',
];
