import { createGlobalStyle } from 'styled-components';

import ResetCss from './reset-css';

const GlobalStyle = createGlobalStyle`
  ${ResetCss}
  
  body {
    margin: 0;
    font-family: ${(props) => props.theme.typography.family.body};
    color: ${(props) => props.theme.colors.grey.dark};
    font-size: 20px;
    font-weight: 400;
    line-height: normal;

    @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
      font-size: 16px;
      line-height: 50px;
    }

    p {
      line-height: normal;
      
      b,
      strong {
        font-weight: 700;
      }
    }
    sup {
      vertical-align: super;
      font-size: smaller;
      align-self: end;
      a{
        color:${(props) => props.theme.colors.primary.default};
        margin-left: 4px;
        cursor: pointer;
        text-decoration:none !important;
      }
    }
    sub {
      vertical-align: sub;
      font-size: smaller;
      align-self:start;
    }
  }

  html {
    box-sizing: border-box;
    overflow-x: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

`;

export default GlobalStyle;
