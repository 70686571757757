'use client';

import dynamic from 'next/dynamic';
import { ThemeProvider } from 'styled-components';
import { config } from '@fortawesome/fontawesome-svg-core';

import { NavigationProvider } from '@/contexts/navigation.context';
import { renderSegmentScript, SegmentProvider } from '@/lib/segment';
import { montserratFont } from '@/utils/fonts';
import theme from '@/utils/styles-variables';
import GlobalStyle from '@/utils/global-styles';

import '@fortawesome/fontawesome-svg-core/styles.css';

import { GTM } from '@/app/components/analytics/gtm';

const CookieBanner = dynamic(() => import('@/components/CookieBanner'), {
  loading: () => <span />,
  ssr: false,
});

config.autoAddCss = false;

export function Client({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <SegmentProvider>
        <NavigationProvider>
          <GlobalStyle />
          <div className={montserratFont.className}>
            <CookieBanner>
              <GTM />
            </CookieBanner>

            {renderSegmentScript()}

            {children}
          </div>
        </NavigationProvider>
      </SegmentProvider>
    </ThemeProvider>
  );
}
