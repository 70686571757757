import { GTMPageViewTracker, GTMNoScript, GTMScript } from '@/app/components/analytics/gtm';

const GTM = () => {
  return (
    <>
      {/* Google Tag Manager - Global base code */}
      <GTMScript />
      {/*  Google Tag Manager (noscript) */}
      <GTMNoScript />
      <GTMPageViewTracker />
    </>
  );
};
export default GTM;
